.Navbar {
    z-index: 1;
    position: fixed;
    top:0px;
    width: 100vw;
    height: 60px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    color:black;   
}

.Frontpage{
    display:block;
   
}

.Navbar .list{
    display: none;    
}

.hamburger{
    display:flex;
    margin-right: 3.54vh;
    cursor: pointer;  
    padding-left: 50px;  
}

.listMobile{
    position: fixed;
    width: 100%;
    display:flex;
    flex-direction: column;
    margin-top: 120px;
    justify-content: center;
    align-items: center;
    gap: 10vh;
    z-index: 1;
    margin: auto;
    top:60px;
    padding-top: 100px;
    padding-bottom: 600px;
    background-color: black;
    opacity: 0.95;
    list-style: none;  
    animation-name: swipe;
    animation-duration: 0.8s;
}

@keyframes swipe {
    from {left:100vh;}
    to {left:0vh;} 
}  

.logo{
    padding-top: 5px;
    padding-left: 10px;
    left:0;
    height: 80%;
    filter: saturate(1.4);   
}

.list a {
    position: relative;
    background-color: white;
    color:white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5em;
    letter-spacing: 0.1em;
    font-weight: 400;
    transition: 0.5s;    
}

.listMobile a {
    position: relative;
    background-color: transparent;
    color:#fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5em;
    letter-spacing: 0.1em;
    font-weight: 400;
    transition: 0.5s;    
}
.listMobile a:hover {
    cursor: pointer;   
}

.list a:focus{
    background-color:#e7bd32;
}

.list a:hover{
    letter-spacing: 0.15em;   
    background: #e7bd32;
    color: #e7bd32;
    box-shadow: 0 0 2px  #e7bd32;
    cursor:pointer;
}

.list a:before{
    content: "";
    position: absolute;
    inset: 2px;
    background:black;
} 
.socLinks1{
    justify-content:space-around;
    align-items: center;
    opacity: 0.9;
    margin:auto;
    
    margin-top: 18px;
    display:flex;
    flex-direction: row;
    gap: 8vw;
    padding-bottom: 1vh;   
}

.socLinks1 a{
align-items: center;
margin-bottom: 5px;
}

.socLinks1 a:hover{
    align-items: center;
    margin-bottom: 5px;
}


.list a span{
    position: relative;
    z-index: 1;  
    top:-0.3vh; 
    margin-left:1.5vw; 
    margin-right:1.5vw; 
}

.list a i:before {
    content: '';
    position: absolute;
    top:0;
    left:80%;
    width: 10px;
    height: 2px;
    background:black;
    transform: translateX(-50%), skewX(325deg);
    transition: 0.3s;
}

.list a:hover i:before {
    content: '';
    position: absolute;
    top:0;
    left:20%;
    width: 20px;
    height: 2px;
    background:black;
    transform: translateX(-50%), skewX(325deg);
    transition: 0.3s;
}
@media only screen and (max-width: 440px) {
    .hamburger{
    padding-left: 10px;
 }
}


@media only screen and (min-width: 940px) {
    .hamburger{
        display:none;   
    }

    .listMobile{ 
        display:none;
        list-style: none;   
    }

    

    .Navbar {
        z-index: 1;
        position: fixed;
        margin-left: 0;
        top:0px;
        width: 100vw;
        height: 80px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;    
    }

    .socLinks1{
        justify-content: center;
        align-items: center;
        
        margin: auto;
        margin-top: 15px;
        display:none;
        flex-direction: row;
        gap: 10vw;
        padding-bottom: 1vh;   
    }
    
    .socLinks1 a{
    align-items: center;
    margin-bottom: 5px;
    }
    
    .socLinks1 a:hover{
        align-items: center;
        margin-bottom: 5px;
    }
    .Navbar .list{
        display: flex;
        list-style: none;
        flex-direction: row;
        gap: 2vw;
        font-weight: bold;  
        padding-right: 3vw;
    }



    .logo{
        padding-top: 5px;
        height: 80%;
        left:0px;
        filter: saturate(1.4);
        background-color: black;
    }

    .list a {
        position: relative;
        background-color: white;
        color:white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.5em;
        letter-spacing: 0.1em;
        font-weight: 400;
        transition: 0.5s;  
        
    }

    .list a:hover{
        letter-spacing: 0.15em;   
        background: #e7bd32;
        color: #e7bd32;
        box-shadow: 0 0 15px  #e7bd32;
        cursor:pointer;
    }

    .list a:before{
        content: "";
        position: absolute;
        inset: 2px;
        background:black;
    } 


    
    .list a span{
        position: relative;
        z-index: 1;  
        top:-0.3vh; 
        margin-left:1.5vw; 
        margin-right:1.5vw; 
    }
    
    .list a i:before {
        content: '';
        position: absolute;
        top:0;
        left:80%;
        width: 10px;
        height: 2px;
        background:black;
        transform: translateX(-50%), skewX(325deg);
        transition: 0.3s;
    }

    .list a:hover i:before {
        content: '';
        position: absolute;
        top:0;
        left:20%;
        width: 20px;
        height: 2px;
        background:black;
        transform: translateX(-50%), skewX(325deg);
        transition: 0.3s;
    }
} 
   

