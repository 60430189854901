.FotoAGB{
    top:60px;
    background-image: url("foto.webp");
    background-color: transparent;
    background-size: cover;
    height: 100vh;
    background-position:center;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-position-y: 60px;
    opacity: 0.1;
  }
  
.Adress{
    position: absolute;
    top:80px;
    padding-right: 5vw;
    padding-left: 5vw;
    color: white;
    font-size: 130%;
    background-color: transparent;
    line-height: 2;
    top: 110px;
    
}

  
  
.IMPTitle{
    position: absolute;
    padding-right: 5vw;
    padding-left: 5vw;
    color: white;
    font-size: 130%;
    background-color: transparent;
    line-height: 2;
    top: 110px;
}

.TextAGB1{
    position: absolute;
    padding-left: 5vw;
    padding-right: 5vw;
    color: white; 
    font-size: 1.2;
    background-color: transparent;
    line-height: 2;
    top:180px;
}
.TextAGB2{
    position: absolute;
    padding-left: 5vw;
    padding-right: 5vw;
    color: white; 
    font-size: 1.2;
    background-color: transparent;
    line-height: 2;
    top:210px;
}
.TextAGB3{
    position: absolute;
    padding-left: 5vw;
    padding-right: 5vw;
    color: white; 
    font-size: 1.2;
    background-color: transparent;
    line-height: 2;
    top:240px;
}
.TextAGB4{
    position: absolute;
    padding-left: 5vw;
    padding-right: 5vw;
    color: white; 
    font-size: 1.2;
    background-color: transparent;
    line-height: 2;
    top:270px;
}
    .TextAGB5{
    position: absolute;
    padding-left: 5vw;
    padding-right: 5vw;
    color: white; 
    font-size: 1.2;
    background-color: transparent;
    line-height: 2;
    top:300px;
}

.TextAGB6{
    position: absolute;
    padding-left: 5vw;
    padding-right: 5vw;
    color: white; 
    font-size: 1.2;
    background-color: transparent;
    line-height: 2;
    top:330px;
}
  
  
@media screen and (max-width: 400px){
    .Text1AGB{
        position: absolute;
        padding-right:5vw;
        padding-left:5vw;
        color: white; 
        font-size: 90%;
        background-color: transparent;
        line-height: 2;
        top:100px;
    }

    .TextAGB{
        position: absolute;
        padding-left: 5vw;
        padding-right: 5vw;
        color: white; 
        font-size: 80%;
        background-color: transparent;
        line-height: 2;
        top:150px;
    }

}

  
  