

    

    
    

.Navbar1 {
    z-index: 1;
    position: fixed;
    top:0px;
    width: 100vw;
    height: 60px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;    
}

.Navbar1 .list2{
    display: flex;
    list-style: none;
    flex-direction: row;
    gap: 2vw;
    font-weight: bold;  
    padding-right: 20px;
}



.logo1{
    padding-top: 5px;
    padding-left: 10px;
    left:0;
    height: 80%;
    filter: saturate(1.4); 
}

.list2 a {
    position: relative;
    background-color: white;
    color:white;
    text-decoration: none;
    text-transform: uppercase;
    margin-right: 10px;;
    font-size: 1.5em;
    letter-spacing: 0.1em;
    font-weight: 400;
    transition: 0.5s;      
}

.list2 a:hover{
    letter-spacing: 0.15em;   
    background: #e7bd32;
    color: #e7bd32;
    box-shadow: 0 0 15px  #e7bd32;
    cursor:pointer;
}

.list2 a:before{
    content: "";
    position: absolute;
    inset: 2px;
    background:black;
} 



.list2 a span{
    position: relative;
    z-index: 1;  
    top:-0.3vh; 
    margin-left:1.5vw; 
    margin-right:1.5vw; 
}

.list2 a i:before {
    content: '';
    position: absolute;
    top:0;
    left:80%;
    width: 10px;
    height: 2px;
    background:black;
    transform: translateX(-50%), skewX(325deg);
    transition: 0.3s;
}

.list2 a:hover i:before {
    content: '';
    position: absolute;
    top:0;
    left:20%;
    width: 20px;
    height: 2px;
    background:black;
    transform: translateX(-50%), skewX(325deg);
    transition: 0.3s;
}

   

