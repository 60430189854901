.Footer{
    width: 260px;
    justify-content: center;
    align-items: center;
    margin: auto;
    z-index: 1;
    margin-top: 20px;
}

.socLinks{
    justify-content: center;
    align-items: center;
    margin: auto;
    display:flex;
    flex-direction: row;
    gap: 60px;
    padding-bottom: 15px;
      
}
.Insta{
    transition: transform .4s ease-in-out;
}

.Insta:hover{
    transform: rotate(135deg);
}

.Facebook{
    transition: transform .4s ease-in-out;
}

.Facebook:hover{
    transform: rotate(135deg);
}
.Youtube{
    transition: transform .4s ease-in-out;
}

.Youtube:hover{
    transform: rotate(135deg);
}

.socLinks a{
align-items: center;
}

.socLinks a:hover{
opacity: 0.6;   
}

.ImprAGB {
    width: 180px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2px;
    
}

.ImprAGB a{
    font-size: 12px;
    color:white;
    text-decoration: none;
}

.ImprAGB a:hover{
    color:#e7bd22;
    text-decoration: underline;
}


.Copyright p{
    font-size: 10px;
    color:white;
    text-align: center; 
    padding-bottom: 10px; 
}

@media screen and (min-width: 420px){
    .Footer{
        width: 280px;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 2vh;
    }
    
    .socLinks{
        justify-content: center;
        align-items: center;
        margin: auto;
        display:flex;
        flex-direction: row;
        gap: 60px;
        padding-bottom: 15px; 
    }
    .socLinks a{
        align-items: center;
        }
}