


.Kontakt {
    background-color: white;
    padding: 20px 10px;
  }
  
  .Kontakttitle {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    background-color: white;
    text-align: center;
    font-weight: bolder;
    font-size: larger;
    color: black;
  }
  
  .Kontakt1 {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    background-color: white;
    text-align: center;
    font-weight: 300;
    color: black;
  }
  
  .Kontakt2 {
    background-color: transparent;
    text-align: center;
    font-weight: bolder;
    font-size: larger;
    color: white;
  }
  
  .Kontakt3 {
    background-color: transparent;
    text-align: center;
    color: white;
    margin-top: 5px;
  }
  
  .Background {
    width: 100%;
    
    background-image: url("BL_total.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    padding: 30vh 0;
  }
  
  .Field {
    width: 400px;
    margin: auto;
    background-color: rgb(43, 43, 43);
    opacity: 0.95;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 1.6vh 1.6vw;
    overflow: hidden;
    box-shadow: 0 0 15px #000000;
  }
  
  /* Helper text for validation errors */
  .helper-text {
    font-size: 0.5rem;
    color: white;
    margin-left: 10px;
    text-align: left;
    font-weight: 400;
  }
  
  /* Style the placeholder color */
  ::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  input::placeholder,
  textarea::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .submit:hover {
    opacity: 0.6;
  }
  
  @media screen and (max-width: 420px) {
    .Background {
      background-image: none;
      padding-top: 100px;
      padding-bottom: 30px;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  
    .Field {
      width: 90vw;
      margin: auto;
      margin-top: -25px;
      margin-bottom: 10px;
      background-color: rgb(43, 43, 43);
      opacity: 0.95;
      border-radius: 10px;
      padding: 20px 10px;
      box-shadow: 0 0 15px #000000;
    }
  }
  
  @media screen and (min-width: 940px) {
    .Background {
      padding: 30vh 0;
    }
  
    .Field {
        width: 400px;
        margin: auto;
        margin-top: 0vh;
        background-color: rgb(43, 43, 43);
        opacity: 0.95;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        padding: 1.6vh 1.6vw;
        box-shadow: 0 0 15px #000000;
      }
  }
  