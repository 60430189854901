@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

*{
    margin:0;
    padding:0; 
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;  
    
    scroll-behavior: smooth;
      
}



