.Rep{
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
}
.repTitel{
  padding-top: 2vh;
  padding-bottom: 1vh;
  background-color: white;
  text-align: center;  
  font-weight:bolder;
  font-size: larger; 
  color: black;
}

.repText{
  background-color: white;
  padding-left:5vw;
  padding-right:5vw;
  padding-bottom: 2vh;
  text-align: center;   
}

.Repertoire{
  background-color: white;
  width: 90vw;
  justify-content: center;
  margin:auto;
  background-color: black;
  padding-bottom: 10vh;
  
}

.form1{
width: 30vw;
display:flex;
flex-direction: column;
background-color: black;
color:white;
justify-content: center;
margin: auto;
padding: 5vh 0;
}
.form1 :focus{
box-shadow: 0 0 15px #e7bd42;
}

#stext{
  background-color: rgb(43, 43, 43);
  color:white; 
  border: #ddd 2px solid;
  border-radius: 5px;
  padding: 0.5vh 3vw;
  text-align: center;
  outline: none;
  caret-color: transparent;
}



#songs {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: rgb(43, 43, 43);
}

#songs td, #songs th {
  border: 1px solid #ddd;
  padding: 8px;
  background-color: rgb(43, 43, 43);
  color:white;
  font-size: 80%;
}

#songs tr:nth-child(even){background-color: #f2f2f2;}

#songs tr:hover {background-color: #ddd;}

#songs th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  font-size: 80%;
  background-color: #e7bd32 ;
  color: black;
}


@media screen and (max-width: 400px){
  
  
  #songs td, #songs th {
    border: 1px solid #ddd;
    padding: 8px;
    background-color: rgb(43, 43, 43);
    color:white;
    font-size: 60%;
  }
  
  #songs tr:nth-child(even){background-color: #f2f2f2;}
  
  #songs tr:hover {background-color: #ddd;}
  
  #songs th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    font-size: 60%;
    background-color: #e7bd32 ;
    color: black;
  }
}


@media screen and (min-width: 940px){
.Rep{
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;
  }
  .repTitel{
    padding-top: 2vh;
    padding-bottom: 1vh;
    background-color: white;
    text-align: center;  
    font-weight:bolder;
    font-size: larger; 
    color: black;
  }

  .repText{
    background-color: white;
    padding-left:20vw;
    padding-right:20vw;
    padding-bottom: 2vh;
    text-align: center;   
  }

  .Repertoire{
    background-color: white;
    width: 80vw;
    justify-content: center;
    margin:auto;
    margin: 0 10vw;
    background-color: black;
    padding-bottom: 10vh;
  }

  .form1{
  width: 30vw;
  display:flex;
  flex-direction: column;
  background-color: black;
  color:white;
  justify-content: center;
  margin: auto;
  padding: 70px 0;
  }
  .form1 :focus{
  box-shadow: 0 0 15px #e7bd42;
  }

  #stext{
    background-color: rgb(43, 43, 43);
    color:white; 
    caret-color: transparent;
    border-radius: 5px;
    font-size: larger;
    padding: 0.5vh 3vw;
    outline: none;
  }



  #songs {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: rgb(43, 43, 43);
  }

  #songs td, #songs th {
    border: 1px solid #ddd;
    padding: 8px;
    background-color: rgb(43, 43, 43);
    color:white;
  }

  #songs tr:nth-child(even){background-color: #f2f2f2;}

  #songs tr:hover {background-color: #ddd;}

  #songs th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    font-size: larger;
    background-color: #e7bd32 ;
    color: black;
  }
}
